/**
 * Router Routes
 */

// Dependencies
import { Component } from 'vue'
import { Route } from 'vue-router'
import { store } from '@/store'

// Views
const ViewMain = () => import(/* webpackChunkName: "main" */ '@/views/Main.vue')
const ViewPublic = () => import(/* webpackChunkName: "login" */ '@/views/Public.vue')

// Partials
const Login = () => import(/* webpackChunkName: "login" */ '@/views/partials/Login.vue')
const LoginPassword = () => import(/* webpackChunkName: "login" */ '@/views/partials/LoginPassword.vue')
const Checkins = () => import(/* webpackChunkName: "main" */ '@/views/partials/Checkins.vue')
const Checkin = () => import(/* webpackChunkName: "checkin" */ '@/views/partials/Checkin.vue')
const Account = () => import(/* webpackChunkName: "account" */ '@/views/partials/Account.vue')

// Components
const MainHeader = () => import(/* webpackChunkName: "main" */ '@/components/MainHeader.vue')
const MainFooter = () => import(/* webpackChunkName: "main" */ '@/components/MainFooter.vue')

// Functions
const mainComponents = (content: any, modal?: any) => ({
	header: MainHeader,
	content,
	footer: MainFooter,
})

const publicComponents = (content: any) => ({
	content,
})

async function authGuard (to: Route, from: Route, next: Function): Promise<void> {
	if (Object.keys(store.getters['user/user'] || {}).length) {
		next()
	} else {
		try {
			await store.dispatch('user/loadUser')
			next()
		} catch (error) {
			next({ name: 'login', query: { from: to.name }})
		}
	}
}

// Routes
export const routes = [
	{
		path: '/',
		component: ViewMain,
		beforeEnter: authGuard,
		children: [
			{
				name: 'main',
				path: '',
				components: mainComponents(Checkins),
				meta: {},
			},
			{
				name: 'checkin',
				path: 'checkin/:id',
				components: mainComponents(Checkin),
				meta: {},
			},
			{
				name: 'account',
				path: 'account',
				components: mainComponents(Account),
				meta: {},
			},
		],
	},
	{
		path: '/login',
		component: ViewPublic,
		children: [
			{
				name: 'login',
				path: '',
				components: publicComponents(Login),
				meta: {},
			},
			{
				name: 'password',
				path: 'password',
				components: publicComponents(LoginPassword),
				meta: {},
			}
		],
	},
]
