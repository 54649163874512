/**
 * API Request Handler
 */

// Dependencies
import Axios from 'axios'

import { FB } from '@/lib/fb'

// Settings
const API_URL = process.env.API_URL || process.env.VUE_APP_API_URL

export class API {

	private static instance: API

	private constructor() {
		Axios.defaults.baseURL = API_URL
		Axios.defaults.headers.common['Content-Type'] = 'application/json'
	}

	static getInstance(): API {
		if (!API.instance) {
			API.instance = new API()
		}

		return API.instance
	}

	public async get(path: string, params: Collection, options: Collection = {}, withAuth: boolean = true, headers: Collection = {}): Promise<any> {

		if (withAuth) {
			const fb = FB.getInstance()
			const user = fb.auth.currentUser
			if (user) {
				const idToken: string = await user.getIdToken()
				headers.Authorization = `Bearer ${idToken}`
			}
		}

		return this._request({
			method: 'GET',
			url: path,
			params,
			headers,
			...options,
		})
	}

	public async send(path: string, data: Collection, options: Collection = {}, withAuth: boolean = true, headers: Collection = {}): Promise<any> {
		if (withAuth) {
			const fb = FB.getInstance()
			const user = fb.auth.currentUser
			if (user) {
				const idToken: string = await user.getIdToken()
				headers.Authorization = `Bearer ${idToken}`
			}
		}

		return this._request({
			method: 'POST',
			url: path,
			data,
			headers,
			...options,
		})
	}

	public async update(path: string, data: Collection, options: Collection = {}, withAuth: boolean = true, headers: Collection = {}): Promise<any> {
		if (withAuth) {
			const fb = FB.getInstance()
			const user = fb.auth.currentUser
			if (user) {
				const idToken: string = await user.getIdToken()
				headers.Authorization = `Bearer ${idToken}`
			}
		}

		return this._request({
			method: 'PUT',
			url: path,
			data,
			headers,
			...options,
		})
	}

	private _request(params: Collection) {
		return new Promise((resolve, reject) => {
			Axios({ ...params })
				.then(r => r.data)
				.then(resolve)
				.catch((error) => {
					if (!error.response) {
						reject(`Network connection error. Please try again later.`)
					} else {
						reject(((error || {}).response || {}).data)
					}
				})
		})
	}
}
