




import Vue from 'vue'
import { FB } from '@/lib/fb'
import { smoothScroll } from '@/lib/utils'
import { MetaInfo } from 'vue-meta'

export default Vue.extend({
	name: 'App',
	data: () => ({
		fb: {} as FB
	}),
	created() {
		smoothScroll()
		this.fb = FB.getInstance()
		document.addEventListener('SWUpdated', this.showRefresh)
	},
	methods: {
		showRefresh(evt: any): void {
			this.$notice(`New version available! Please refresh to update.`, () => {
				evt.detail.postMessage({ action: 'skipWaiting' })
			}, {
				actionButtonText: `REFRESH`,
				timeoutMs: 10000,
			})
		}
	},
	metaInfo: (): MetaInfo => ({
		title: 'Dashboard',
		titleTemplate: '%s | Quick Patient',
		meta: [
				{
					charset: 'utf-8',
				},
				{
					httpEquiv: 'X-UA-Compatible',
					content: 'IE-edge',
				},
				{
					name: 'description',
					content: '',
				},
				{
					name: 'viewport',
					content: 'width=device-width, initial-scale=1, maximum-scale=5',
				},
		],
		link: [
			{
				rel: 'stylesheet',
				href: '//fonts.googleapis.com/icon?family=Source+Sans+Pro:300,400,600,700,900|Lato:300,400,700,900|Material+Icons|Material+Icons+Outlined&display=swap',
			},
		],
	}),
})
