// Dependencies
import { VueConstructor } from 'vue'

import Confirm from './Confirm.vue'

// Plugin
export const ConfirmPlugin = {
	install(Vue: VueConstructor) {
		const CONSTRUCTOR = Vue.extend(Confirm)
		const CACHE: Collection = {}

		function confirm(message: string, acceptAction: Function = () => ({}), options: Collection = {}): void {
			options.id = options.id || 'confirm'
			const confirm = CACHE[options.id] || (CACHE[options.id] = new CONSTRUCTOR())

			options.message = message
			options.acceptAction = acceptAction

			if (!confirm.$el) {
				const vm = confirm.$mount()
				document.querySelector(options.parent || 'body').appendChild(vm.$el)
			}

			confirm.show(options)
		}

		function confirmClose( options: Collection = {}) {
			const confirm = CACHE[options.id] || (CACHE[options.id] = new CONSTRUCTOR())
			confirm.close()
		}

		Vue.prototype.$confirm = confirm
		Vue.prototype.$confirmClose = confirmClose
	}
}
