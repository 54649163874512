/**
 * Vuex Modules: Checkins
 */

// Dependencies
import { Commit, Dispatch, StoreOptions } from 'vuex'
import Dexie from 'dexie'
import { startOfToday } from 'date-fns'
import { v4 } from 'uuid'
import * as types from '../types'

import { FB } from '@/lib/fb'
import { API } from '@/lib/api'
import { CheckinStatus } from '@/types/types'
import { parseImage, mergeImage, formFields } from '@/lib/utils'
import firebase from 'firebase/app'

const fb = FB.getInstance()
const db = new Dexie('qpCheckins')
db.version(1).stores({ checkins: 'id, createdAt' })

const DEFAULTS: Collection = {
	requestId: null,
	locationId: null,
	checkins: [] as Collection[],
	checkin: {} as Collection,
	open: false as boolean,
	loading: 0 as number,
	forms: [] as Collection[],
}

const state: Collection = {
	...DEFAULTS,
	...{
		db,
	}
}

const actions = {
	async init({ commit, state, dispatch } : { commit: Commit, state: Collection, dispatch: Dispatch }) {
		await state.db.open()
		await dispatch('checkins/prune')
		await dispatch('checkins/loadCheckins')
	},
	async sendSms({ commit } : { commit: Commit }, message: Collection) {
		const api = API.getInstance()
		let res: Collection = {}
		try {
			res = await api.send(`/sms/send`, { to: message.to, body: message.body })
		} catch (error) {
			throw error
		}

		if (res.sid) {
			try {
				res = await new Promise((resolve, reject) => {
					if (fb.refs.sms) {
						let messageRef = fb.refs.sms.child(res.sid)
						messageRef.on('child_changed', async (snap: firebase.database.DataSnapshot) => {
							if (snap.val() == 'delivered') {
								messageRef.off('child_changed')
								resolve({
									...res,
									status: snap.val()
								})
							}
							if (snap.val() == 'fail') {
								messageRef.off('child_changed')
								reject('Can not send')
							}
						})
					}
				})
			} catch (error) {
				throw error
			}
		}

		return res
	},
	async prune({ state } : { state: Collection }) {
		const cutoff = startOfToday().getTime()
		const checkins = await state.db.checkins
			.where('createdAt')
			.below(cutoff)
			.toArray()

		return checkins.forEach(async (checkin: Collection): Promise<void> => {
			try {
				await state.db.checkins.delete(checkin.id)
			} catch (error) {
				console.error(error)
			}
		})
	},
	async loadCheckins({ commit, state, dispatch } : { commit: Commit, state: Collection, dispatch: Dispatch}) {
		dispatch('misc/setLoading', true, { root: true })
		const checkins = await state.db.checkins
			.orderBy('createdAt')
			// .reverse()
			.toArray()

		dispatch('misc/setLoading', false, { root: true })
		return commit(types.SET_CHECKINS, checkins)
	},
	async unloadCheckins({ commit } : { commit : Commit }) {
		commit(types.UNSET_CHECKINS)
	},
	async loadCheckin({ commit, dispatch } : { commit: Commit, dispatch: Dispatch }, id: string) {
		dispatch('misc/setLoading', true, { root: true })
		const checkin = await state.db.checkins.get(id)
		dispatch('misc/setLoading', false, { root: true })
		commit(types.SET_CHECKIN, checkin)
		return checkin
	},
	async unloadCheckin({ commit } : { commit: Commit }) {
		commit(types.UNSET_CHECKIN)
	},
	async loadForms({ commit } : { commit: Commit }) {
		try {
			if (fb.refs.forms) {
				const formsSnap = await fb.refs.forms.once('value')
				const forms = formsSnap.val()
				if (forms) {
					commit(types.SET_FORMS, forms)
				}
			}
		} catch (error) {

		}
	},
	async unloadForms({ commit } : { commit: Commit }) {
		commit(types.UNSET_FORMS)
	},
	async addCheckin({ commit, dispatch }: { commit: Commit, dispatch: Dispatch }, profile: Collection) {
		const uuid = v4()
		const checkin: Collection = {
			...profile,
			...{
				id: uuid,
				status: CheckinStatus.New,
				createdAt: (new Date()).getTime(),
				expiresAt: (new Date()).getTime() + (1000 * 60 * 60 * 24),
			}
		}

		state.db.checkins.put(checkin)
			.then(() => {
				commit(types.ADD_CHECKIN, checkin)
				try {
					dispatch('notifications/sendNotification', {
						title: `New Patient Check-In`,
						options: {
							body: `${checkin.firstName} ${checkin.lastName} has checked in!`,
							icon: `${process.env.BASE_URL}img/icons/android-chrome-512x512.png`,
						}
					}, { root: true })
				} catch (error) {
					console.log(`Notifications not enabled.`, error)
				}
			})

		return checkin
	},
	async updateCheckin({ commit } : { commit: Commit, state: Collection }, checkin: Collection) {
		const update = await state.db.checkins.update(checkin.id, checkin)
		if (update) {
			return commit(types.UPDATE_CHECKIN, checkin)
		}
	},
	async deleteCheckin({ commit, state, dispatch } : { commit: Commit, state: Collection, dispatch: Dispatch }, checkin: Collection) {
		dispatch('misc/setLoading', true, { root: true })
		const deleted = await state.db.checkins.delete(checkin.id)
		dispatch('misc/setLoading', false, { root: true })
		if (deleted) {
			return commit(types.DELETE_CHECKIN, checkin)
		}
	},
	async acceptCheckin({ commit, state, dispatch } : { commit: Commit, state: Collection, dispatch: Dispatch }, checkin: Collection) {
		dispatch('misc/setLoading', true, { root: true })
		checkin.status = CheckinStatus.Accepted
		checkin.acceptedAt = (new Date()).getTime()
		const update = await state.db.checkins.update(checkin.id, checkin)
		dispatch('misc/setLoading', false, { root: true })
		if (update) {
			return commit(types.UPDATE_CHECKIN, checkin)
		}
	},
	async transferCheckin({ commit, state, dispatch } : { commit: Commit, state: Collection, dispatch: Dispatch }, checkin: Collection) {
		dispatch('misc/setLoading', true, { root: true })
		dispatch('setCheckinLoading', true)

		try {
			if (checkin.photo) {
				checkin.photoData = await parseImage(checkin.photo)
			}
			if (checkin.licensePhoto) {
				checkin.licensePhotoData = await parseImage(checkin.licensePhoto)
			}
			if (checkin.signature) {
				checkin.signature = await parseImage(checkin.signature)
			}
			if (checkin.covidCardFront) {
				checkin.covidCardFrontData = await parseImage(checkin.covidCardFront)
			}
			if (checkin.covidCardBack) {
				checkin.covidCardBackData = await parseImage(checkin.covidCardBack)
			}
			if (checkin.insurancePhotoFront && checkin.insurancePhotoBack) {
				checkin.insurancePhotoData = await mergeImage(checkin.insurancePhotoFront, checkin.insurancePhotoBack)
			} else if (checkin.insurancePhotoFront) {
				checkin.insurancePhotoData = await parseImage(checkin.insurancePhotoFront)
			} else if (checkin.insurancePhotoBack) {
				checkin.insurancePhotoData = await parseImage(checkin.insurancePhotoBack)
			}

			if (checkin.historyMedical) {
				const field = formFields.find((f: Collection) => f.name === 'historyMedical')
				let listMatches: string[] = []
				if (field) {
					for (const item in checkin.historyMedical) {
						const found = field.children.find((f: Collection) => f.name === item)
						if (found) {
							listMatches.push(found.label)
						}
					}
				}

				checkin.historyMedicalList = listMatches
			}
		} catch (error) {
			dispatch('misc/setLoading', false, { root: true })
			dispatch('setCheckinLoading', false)

		}

		function transferStatusHandler (event: MessageEvent) {
			if (event.data && event.data.status) {
				switch (event.data.status) {
					case 'photoTransferStart':
						dispatch('misc/setLoading', true, { root: true })
						dispatch('setCheckinLoading', true)
						break
					case 'photoTransferEnd':
						dispatch('misc/setLoading', false, { root: true })
						dispatch('setCheckinLoading', false)
						break
					case 'photoTransferFail':
						dispatch('misc/setLoading', false, { root: true })
						dispatch('setCheckinLoading', false)
						break
					case 'complete':
						dispatch('misc/setLoading', false, { root: true })
						dispatch('setCheckinLoading', false)
						break
				}
			}
		}

		window.addEventListener('message', transferStatusHandler, true)

		window.parent.postMessage({
			type: 'qp_profile',
			data: JSON.parse(JSON.stringify(checkin))
		}, '*')

		// dispatch('misc/setLoading', false, { root: true })
	},
	setCheckinLoading({ commit }: { commit: Commit }, status: boolean) {
		if (status) {
			commit(types.SET_CHECKIN_LOADING, 1)
		} else {
			commit(types.SET_CHECKIN_LOADING, -1)
		}
	},

}

const mutations = {
	[types.SET_CHECKINS](state: Collection, checkins: Collection[]) {
		state.checkins = checkins
	},
	[types.SET_CHECKIN](state: Collection, checkin: Collection) {
		state.checkin = checkin
	},
	[types.ADD_CHECKIN](state: Collection, checkin: Collection) {
		state.checkins.push(checkin)
	},
	[types.UNSET_CHECKINS](state: Collection) {
		state.checkins = DEFAULTS.checkins
	},
	[types.UNSET_CHECKIN](state: Collection) {
		state.checkin = DEFAULTS.checkin
	},
	[types.DELETE_CHECKIN](state: Collection) {
		state.checkin = DEFAULTS.checkin
	},
	[types.UPDATE_CHECKIN](state: Collection, checkin: Collection) {
		state.checkin = checkin
	},
	[types.SET_CHECKIN_LOADING](state: Collection, status: number) {
		state.loading = Math.max(0, state.loading + status)
	},
	[types.SET_FORMS](state: Collection, forms: Collection[]) {
		state.forms = forms
	},
	[types.UNSET_FORMS](state: Collection) {
		state.forms = DEFAULTS.forms
	}
}

const getters = {
	checkins: (state: Collection) => state.checkins,
	checkin: (state: Collection) => state.checkin,
	loading: (state: Collection) => !!state.loading,
	forms: (state: Collection) => state.forms,
	formsFields: (state: Collection) => state.forms.filter((f: Collection) => f.type != 'modList').map((f: Collection) => f.name),
	formsFieldsMods: (state: Collection) => state.forms.filter((f: Collection) => f.type == 'modList'),
	newFormsFields: (state: Collection) => {
		let formSplices: Collection[] = []
		let group: Collection[] = []
		let type: string = ''

		state.forms.forEach((field: Collection,idx: number) => {
			if (field.type != type) {
				if (group.length) {
					let fields = group.map((f: Collection) => f.name)
					formSplices.push({type, group, fields})
					group = [field]
				} else {
					group.push(field)
				}
				type = field.type
			} else {
				group.push(field)
			}

			if (idx == (state.forms.length - 1)) {
				let fields = group.map((f: Collection) => f.name)
				formSplices.push({type, group, fields})
			}
		})

		return formSplices
	}
}

export default {
	namespaced: true,
	state,
	actions,
	mutations,
	getters,
}
