import { formatDistance, formatDistanceStrict, parseISO, parse } from 'date-fns'

export default function timeAgo(val: any = 0, addSuffix: boolean = true, strict: boolean = false) {
	if (!val) return ''
	if (typeof val.toDate !== 'undefined') {
		val = val.toDate()
	}

	try {
		let parsed = parseISO(val)
		if (parsed instanceof Date && !isNaN(parsed.getTime())) {
			val = parsed
		}
	} catch (error) {
	}


	let formatted: string = val
	if (strict) {
		try {
			formatted = formatDistanceStrict(
				val,
				(new Date()),
				{ addSuffix }
			)
		} catch (error) {
			console.error(error)
		}

		return formatted
	}

	try {
		formatted = formatDistance(
			val,
			(new Date()),
			{ addSuffix },
		)
	} catch (error) {
		console.error(error)
	}

	return formatted
}
