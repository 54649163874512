/**
 * Firebase Class
 */

// Dependencies
import firebase from 'firebase/app'
import 'firebase/auth'
import 'firebase/database'
import 'firebase/storage'

// Definitions
interface Refs {
	agreements: firebase.database.Reference | undefined,
	forms: firebase.database.Reference | undefined,
	open: firebase.database.Reference | undefined,
	receipts: firebase.database.Reference | undefined,
	requests: firebase.database.Reference | undefined,
	responses: firebase.database.Reference | undefined,
	sms: firebase.database.Reference | undefined,
	storage: firebase.storage.Reference | undefined,
	transfers: firebase.database.Reference | undefined,
	user: firebase.database.Reference | undefined,
}

// Config
import { FIREBASE } from '@/config'


export class FB {

	private static instance: FB

	auth: firebase.auth.Auth
	database: firebase.database.Database
	storage: firebase.storage.Storage
	refs: Refs

	private constructor() {
		if (!firebase.apps.length) {
			firebase.initializeApp(FIREBASE)
		}

		this.auth = firebase.auth()
		this.database = firebase.database()
		this.storage = firebase.storage()
		this.refs = {
			agreements: undefined,
			forms: undefined,
			open: undefined,
			receipts: undefined,
			requests: undefined,
			responses: undefined,
			sms: undefined,
			storage: undefined,
			transfers: undefined,
			user: undefined,
		}
	}

	static getInstance(): FB {
		if (!FB.instance) {
			FB.instance = new FB()
		}

		return FB.instance
	}

	public setRefs(locationId: string, userId: string) {
		this.refs = {
			agreements: this.database.ref('agreements').child(locationId),
			forms: this.database.ref('forms').child(locationId),
			open: this.database.ref('open').child(locationId),
			receipts: this.database.ref('receipts').child(locationId),
			requests: this.database.ref('requests').child(locationId),
			responses: this.database.ref('responses').child(locationId),
			sms: this.database.ref('sms').child(locationId),
			storage: this.storage.ref('transfers').child(locationId),
			transfers: this.database.ref('transfers').child(locationId),
			user: this.database.ref('users').child(userId),
		}
	}

	public unsetRefs() {
		this.refs = {
			agreements: undefined,
			forms: undefined,
			open: undefined,
			receipts: undefined,
			requests: undefined,
			responses: undefined,
			sms: undefined,
			storage: undefined,
			transfers: undefined,
			user: undefined,
		}
	}

	// public refs(): Collection {
	// 	return {
	// 		open: this.database.ref('open'),
	// 		requests: this.database.ref('requests'),
	// 		responses: this.database.ref('responses'),
	// 		transfers: this.database.ref('transfers'),
	// 	}
	// }
}
