/**
 * Vue Router
 */

// Dependencies
import Vue from 'vue'
import VueRouter, { Route } from 'vue-router'
import { routes } from './routes'
import { store } from '@/store'

// Install
Vue.use(VueRouter)

// Export
export const router = new VueRouter({
	mode: 'history',
	base: process.env.BASE_URL,
	routes,
	scrollBehavior(to, from, saved) {
		if (saved) {
			return saved
		}
		return { x: 0, y: 0 }
	}
})

// AsyncLoading
router.beforeResolve((to, from, next) => {
	const matched = router.getMatchedComponents(to)
	const prevMatched = router.getMatchedComponents(from)

	let diffed: boolean = false
	const activated = matched.filter((c, i) => {
		return diffed || (diffed = (prevMatched[i] !== c))
	})

	if (!activated.length) {
		return next()
	}

	Promise.all(activated.map((c: any) => {
		if (c && c.options && c.options.asyncData) {
			return c.options.asyncData(store, to)
		}
	})).then(() => {
		store.dispatch('user/autoLogout')
		next()
	}).catch(next)
})
