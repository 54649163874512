/**
 * Vuex Module: Users
 */

// Dependencies
import { Commit, Dispatch } from 'vuex'
import firebase from 'firebase/app'
import add from 'date-fns/add'
import Cookies from 'js-cookie'

import * as types from '../types'

import { API } from '@/lib/api'
import { FB } from '@/lib/fb'

// Definitions
const logoutDuration: number = 45
const warningDuration: number = 30
const logoutLength: number = logoutDuration * (60 * 1000)
const warningLength: number = warningDuration * (60 * 1000)

// State
const state = (): Collection => ({
	loading: 0 as number,
	error: '' as string,
	user: {} as Collection,
	settings: {} as Collection,
	warningTimer: 0 as number,
	logoutTimer: 0 as number,
	logoutWarning: false as boolean,
	logoutTime: add((new Date()), { minutes: logoutDuration }),
	warningTime: add((new Date()), { minutes: warningDuration }),
})

// Actions
const actions = {
	async login({ commit, dispatch } : { commit: Commit, dispatch: Dispatch }, credentials: Credentials) {
		dispatch('misc/setError', null, { root: true })
		dispatch('misc/setLoading', true, { root: true })
		dispatch('checkin/prune', null, { root: true })

		const api = API.getInstance()
		const fb = FB.getInstance()

		try {
			const user = await fb.auth.signInWithEmailAndPassword(credentials.email, credentials.password)
			if (!user.user)
				throw new Error(`Can not login. Please verify credentials and try again.`)

			const idToken: string = await user?.user?.getIdToken()

			const token: string = await api.send(`/user/login`, {token: idToken}, {}, false)
			if (!token) {
				throw new Error(`Can not login. Please verify credentials and try again.`)
			}

			await fb.auth.setPersistence(firebase.auth.Auth.Persistence.LOCAL)
			await fb.auth.signInWithCustomToken(token)
		} catch (error) {
			dispatch('checkin/prune', null, { root: true })
			dispatch('transfer/unsetAccepting', fb.auth.currentUser, { root: true })
			await fb.auth.signOut()
			clearTimeout(state().warningTimer)
			clearTimeout(state().logoutTimer)
			commit(types.UNSET_USER, null)
			dispatch('misc/setError', error.message || error, { root: true })
		}

		// try {
		// 	const token: string = await api.send(`/user/login`, credentials, {}, false)
		// 	await fb.auth.setPersistence(firebase.auth.Auth.Persistence.SESSION)
		// 	await fb.auth.signInWithCustomToken(token)
		// } catch (error) {
		// 	dispatch('misc/setError', error.message || error, { root: true })
		// 	// throw error
		// }

		dispatch('misc/setLoading', false, { root: true })
	},

	async logout({ commit, dispatch } : { commit: Commit, dispatch: Dispatch }) {
		const fb = FB.getInstance()
		dispatch('checkin/prune', null, { root: true })
		dispatch('transfer/unsetAccepting', fb.auth.currentUser, { root: true })
		await fb.auth.signOut()
		clearTimeout(state().warningTimer)
		clearTimeout(state().logoutTimer)
		commit(types.UNSET_USER, null)
	},

	autoLogout({ commit }: { commit: Commit }) {
		commit(types.SET_LOGOUT_WARNING, false)
		return commit(types.SET_LOGOUT_TIMER,
			() => {
				commit(types.SET_LOGOUT_WARNING, true)
			})
	},

	async passwordReset({ dispatch } : { dispatch : Dispatch }, { email } : { email: string} ) {
		dispatch('misc/setError', null, { root: true })
		dispatch('misc/setLoading', true, { root: true })

		const fb = FB.getInstance()

		let sent: string = ''
		try {
			await fb.auth.sendPasswordResetEmail(email)
			sent = `Your password email has been sent! Please be sure to check your SPAM folder.`
		} catch (error) {
			dispatch('misc/setError', error.message || error)
		}

		dispatch('misc/setLoading', false, { root: true })

		return sent
	},

	async loadUser({ commit } : { commit: Commit }) {
		const fb = FB.getInstance()

		let authUser: Collection = await new Promise((resolve, reject) => {
			fb.auth.onAuthStateChanged((user: firebase.User | null) => {
				if (user) {
					resolve(user)
				} else {
					reject(`No user`)
				}
			})
		})

		if (authUser && fb.auth.currentUser) {
			const token = await fb.auth.currentUser.getIdTokenResult()
			let { agreed, location, provider, settings } = token.claims

			authUser = {
				...authUser,
				...{
					agreed,
					location,
					provider,
					settings,
				}
			}

			fb.setRefs(token.claims.location.id, authUser.uid)
		} else {
			fb.unsetRefs()
		}

		commit(types.SET_USER, authUser)

		return authUser
	},

	async updateProfile({ commit, dispatch } : { commit: Commit, dispatch: Dispatch }, params: Collection) {
		const fb = FB.getInstance()
		let updated: string = ''
		if (!fb.auth.currentUser) return false

		dispatch('misc/setError', null, { root: true })
		dispatch('misc/setLoading', true, { root: true })

		try {
			await fb.auth.currentUser.updateProfile(params)
			updated = `Your profile has been updated!`
		} catch (error) {
			dispatch('misc/setError', error, { root: true })
		}

		dispatch('misc/setLoading', false, { root: true })
		return updated
		// return Promise.resolve()
	},

	async loadClient({ commit, dispatch }: { commit: Commit, dispatch: Dispatch }, clientId: string) {
		const fb = FB.getInstance()
		if (!fb.auth.currentUser) return false

		dispatch('misc/setError', null, { root: true })
		dispatch('misc/setLoading', true, { root: true })

		let clientData: Collection = {}
		try {
			const clientRef = fb.database.ref('clients').child(clientId)
			const clientSnap = await clientRef.once('value')
			clientData = clientSnap.val()
		} catch (error) {
			dispatch('misc/setError', error, { root: true })
		}

		dispatch('misc/setLoading', false, { root: true })

		return clientData
	},
	resetLogoutTimer({ commit } : { commit: Commit }) {
		return commit(types.SET_LOGOUT_TIMER,
			() => {
				commit(types.SET_LOGOUT_WARNING, true)
			}
		)
	},
}

// Mutations
const mutations = {
	[types.SET_LOGOUT_WARNING](state: Collection, warning: boolean) {
		state.logoutWarning = warning
		state.warningTime = add((new Date()), { minutes: warningDuration })
	},
	[types.SET_LOGOUT_TIMER](state: Collection, warnFn: Function): void {
		clearTimeout(state.warningTimer)
		state.warningTimer = setTimeout(warnFn, warningLength)
		state.logoutTime = add((new Date()), { minutes: logoutDuration })

		clearTimeout(state.logoutTimer)
		state.logoutTimer = setTimeout(this.dispatch, logoutLength, ('user/logout'))
	},
	[types.SET_USER](state: Collection, user: Collection) {
		state.user = user
	},
	[types.UNSET_USER](state: Collection) {
		state.user = null
		state.settings = null
	},
}

// Getters
const getters: Collection = {
	logoutWarning: (state: Collection) => state.logoutWarning,
	user: (state: Collection) => state.user,
	profile: (state: Collection) => {
		if (!state.user) return {}
		return {
			...state.user.providerData[0],
			...state.user.metadata,
			...{
				uid: state.user.uid,
				location: state.user.location,
			},
		}
	},
	provider: (state: Collection) => state.user.provider,
	settings: (state: Collection) => state.user.settings,
	warningTime: (state: Collection) => state.warningTime,
	logoutTime: (state: Collection) => state.logoutTime,
}

// Export Module
export default {
	namespaced: true,
	state,
	actions,
	mutations,
	getters,
}
