/**
 * Vuex Store
 */

import Vue from 'vue'
import Vuex from 'vuex'

// Install
Vue.use(Vuex)

// Modules
import modules from './modules'

export const store = new Vuex.Store({
	modules,
})
