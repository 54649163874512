/**
 * Vue Filters
 */

 // Dependencies
import { VueConstructor } from 'vue'

// Dynamic Import/Export
const files = require.context('.', false, /\.ts$/)

export const FiltersPlugin = {
	install(Vue: VueConstructor) {
		files.keys().forEach((key) => {
			if (key === './index.ts') return
			let name = key.replace(/(\.\/|\.ts)/g, '')
			Vue.filter(name, files(key).default)
		})
	}
}
