/**
 * Vuex Modules: Checkins
 */

// Dependencies
import { Commit, Dispatch, StoreOptions } from 'vuex'
import * as types from '../types'

const DEFAULTS: Collection = {}

const state: Collection = {
	...DEFAULTS,
}

const actions = {
	enableNotifications({ commit, dispatch }: { commit: Commit, dispatch: Dispatch }) {
		navigator.serviceWorker.register('/service-worker.js').then(register => {
			commit(types.ENABLE_NOTIFICATIONS, register)
		})


	},
	sendNotification({ commit, dispatch } : { commit: Commit, dispatch: Dispatch }, message: Collection) {
		navigator.serviceWorker.ready.then(sw => {
			sw.showNotification(message.title, message.options)
		})
	},
}

const mutations = {
	[types.ENABLE_NOTIFICATIONS](state: Collection, register: ServiceWorkerRegistration) {
		state.register = register
	}
}

const getters = {}

export default {
	namespaced: true,
	state,
	actions,
	mutations,
	getters,
}
