const enum TransferStatus { Incoming, Fail, Connecting, Transferring, Transferred, Success, Canceled }

import Vue from 'vue';
import App from './App.vue';
import './registerServiceWorker';
import { router } from '@/router';
import { store } from '@/store';

// Plugins
import { NoticePlugin } from '@/lib/plugins/notice'
Vue.use(NoticePlugin)
import { ConfirmPlugin } from '@/lib/plugins/confirm'
Vue.use(ConfirmPlugin)
import { FiltersPlugin } from '@/lib/filters'
Vue.use(FiltersPlugin)
import VueMeta from 'vue-meta'
Vue.use(VueMeta)

Vue.config.productionTip = false;

new Vue({
	router,
	store,
	render: (h) => h(App),
}).$mount('#app');
