// Dependencies
import { VueConstructor } from 'vue'

import Notice from './Notice.vue'

// Plugin
export const NoticePlugin = {
	install(Vue: VueConstructor) {
		const CONSTRUCTOR = Vue.extend(Notice)
		const CACHE: Collection = {}

		function notice(message: string, action: Function = () => ({}), options: Collection = {}) {
			options.id = options.id || Math.random().toString(36).replace(/[^a-z]+/g, '').substr(0, 5)
			options.message = message
			options.action = action

			const notice = CACHE[options.id] || (CACHE[options.id] = new CONSTRUCTOR())
			if (!notice.$el) {
				const vm = notice.$mount()
				document.querySelector(options.parent || 'body').appendChild(vm.$el)
			}

			notice.queued.push(options)
		}

		Vue.prototype.$notice = notice
	}
}
