/**
 * Vuex Module: Transfer
 */


// Dependencies
import Vue from 'vue'
import { Commit, Dispatch, Getter } from 'vuex'
import pako from 'pako'
import Axios from 'axios'
import firebase from 'firebase/app'
import 'firebase/database'
import 'firebase/storage'

import * as types from '../types'
import { TransferStatus } from '@/types/types'

import { FB } from '@/lib/fb'
import { Secure } from '@/lib/secure'
import { arrayBufferToString } from '@/lib/utils'
import checkin from './checkin'

const fb = FB.getInstance()
const secure = Secure.getInstance()


const DEFAULTS: Collection = {
	open: false as boolean,
	requestId: null,
	requestRef: null,
	responseRef: null,
	transferRef: null,
	requestsListener: null,
}

const state: Collection = {
	...DEFAULTS,
}

const actions = {
	async setAccepting({ commit } : { commit : Commit }, user: Collection) {
		if (!fb.refs.open) return
		const openRef = fb.refs.open

		return openRef.update({
			uid: user.uid,
			createdAt: firebase.database.ServerValue.TIMESTAMP,
		})
	},
	async unsetAccepting({ commit } : { commit : Commit }, user: Collection) {
		if (!fb.refs.open) return
		const openRef = fb.refs.open
		const openSnap = await openRef.once('value')
		if (openSnap.exists()) {
			const openVal = openSnap.val()
			if (openVal.uid === user.uid) {
				return openRef.remove()
			}
		}
		// return openRef.remove()
	},
	async watchOpen({ commit, dispatch, rootGetters } : { commit: Commit, dispatch: Dispatch, rootGetters: any }) {
		const user = rootGetters['user/user']
		if (!user || !user.location || !user.uid) return
		if (!fb.refs.open) return
		const openRef = fb.refs.open

		const checkMatch = (snap: firebase.database.DataSnapshot) => {
			if (!snap.exists() || !snap.val()) {
				commit(types.UNSET_OPEN)
				return dispatch('unwatchTransfers')
			}

			if (snap.key !== 'uid' && snap.key !== user.location.id) {
				return
			}

			let uid: string
			if (snap.key === 'uid') {
				uid = snap.val()
			} else {
				({ uid } = snap.val())
			}

			if (uid != user.uid) {
				commit(types.UNSET_OPEN)
				return dispatch('unwatchTransfers')
			}

			commit(types.SET_OPEN)
			return dispatch('watchTransfers')
		}

		openRef.on('child_removed', checkMatch)
		openRef.on('child_added', checkMatch)
		openRef.on('child_changed', checkMatch)
		openRef.on('value', checkMatch)

		// Check for pings
		openRef.child('ping').on('value', (snap: firebase.database.DataSnapshot) => {
			if (snap.exists()) {
				openRef.child('ping').remove()
			}
		})
	},
	async watchTransfers({ commit, dispatch, state } : { commit: Commit, dispatch: Dispatch, state: Collection }) {
		if (state.requestsListener) return

		let keyPair: Collection | undefined
		let patient: Collection

		const transferError = (error?: string) => {
			keyPair = undefined
			state.responseRef = undefined
			state.requestRef = undefined
			state.transferRef = undefined
			commit(types.SET_TRANSFER_STATUS, TransferStatus.Fail)
			if (error) {
				console.error(error)
			}
		}

		const requestsRef = fb.refs.requests
		if (!requestsRef) return
		state.requestsListener = requestsRef.on('child_added', async (snap: firebase.database.DataSnapshot) => {
			if (!snap.exists()) return

			commit(types.SET_TRANSFER_STATUS, TransferStatus.Incoming)

			let { requestKey } : { requestKey : number[] | undefined } = snap.val()
			if (!requestKey) {
				transferError(`Can not get request key`)
				return snap.ref.remove
			}

			state.requestId = snap.key
			state.requestRef = snap.ref

			snap.ref.remove()

			try {
				keyPair = await secure.generateKeypair()
			} catch (error) {
				return transferError(error)
			}

			if (!keyPair) {
				return transferError(`Can not generate Key pair`)
			}

			if (!fb.refs.responses) {
				return transferError(`Missing ref: responses`)
			}
			state.responseRef = fb.refs.responses.child(state.requestId)

			try {
				state.responseRef.set({
					responseKey: keyPair.publicKey,
				})
			} catch (error) {
				return transferError(error)
			}
			if (!fb.refs.transfers) {
				return transferError(`Missing ref: transfers`)
			}
			if (!fb.refs.storage) {
				return transferError(`Missing ref: storage`)
			}

			commit(types.SET_TRANSFER_STATUS, TransferStatus.Connecting)

			state.storageRef = fb.refs.storage.child(state.requestId)
			state.transferRef = fb.refs.transfers.child(state.requestId)

			state.transferRef.once('child_added', async (transferSnap: firebase.database.DataSnapshot) => {
				commit(types.SET_TRANSFER_STATUS, TransferStatus.Transferring)

				let data = transferSnap.val()

				state.responseRef.remove()
				state.transferRef.remove()

				// Convert data type
				try {
					let strData = atob(data)
					let charData = strData.split('').map(x => x.charCodeAt(0))
					let binData = new Uint8Array(charData)
					let dataArr = pako.inflate(binData)
					data = arrayBufferToString(dataArr)
				} catch (error) {
					return transferError(error)
				}

				if (!data) {
					return transferError(`Can not convert transfer data`)
				}

				let requestPublicKey: Uint8Array | undefined
				let requestSignKey: Uint8Array | undefined
				let nonce
				let message
				let signed

				// Parse keys from transfer
				if (!requestKey) {
					return transferError(`No request key`)
				}

				try {
					({ requestPublicKey, requestSignKey } = await secure.parseKeys(requestKey))
				} catch (error) {
					return transferError(error)
				}

				if (!requestPublicKey || !requestSignKey) {
					return transferError()
				}

				try {
					({ nonce, message, signed } = await secure.parseEncryptedString(data))
				} catch (error) {
					requestPublicKey = undefined
					requestSignKey = undefined
					return transferError(error)
				}

				if (!keyPair) {
					return transferError(`No key pair generated`)
				}

				try {
					const decrypted = await secure.decrypt(message, signed, nonce, requestPublicKey, keyPair.privateKey, requestSignKey)
					patient = JSON.parse(decrypted)
				} catch (error) {
					return transferError(error)
				}

				try {
					const imageFields: string[] = [
						'photo',
						'licensePhoto',
						'insurancePhotoFront',
						'insurancePhotoBack',
						'covidCardFront',
						'covidCardBack',
						'signature',
					]

					for (const imageField of imageFields) {
						if (patient[imageField]) {
							const imageRef = state.storageRef.child(`${patient[imageField]}.txt`)

							try {
								const imageUrl = await imageRef.getDownloadURL()
								const imageRes = await Axios.get(imageUrl)
								let { nonce, message } = await secure.parseNonce(imageRes.data)
								const imgString = await secure.decryptImage(message, nonce, requestPublicKey, keyPair.privateKey)
								patient[imageField] = imgString
								imageRef.delete()
							} catch (error) {
								console.error(error)
								imageRef.delete()
							}

							try {
								imageRef.delete()
							} catch (error) {

							}
						}
					}
				} catch (error) {
					return transferError(error)
				}

				keyPair = undefined
				requestKey = undefined

				try {
					if (fb.refs.agreements) {
						const agreementsSnap = await fb.refs.agreements.once('value')
						const agreements = agreementsSnap.val()
						patient.agreements = agreements.map((a: Collection, i: number) => ({
							...patient.agreements[i],
							content: a.content,
						}))
					}
				} catch (error) {

				}

				await fb.refs.receipts?.child(state.requestId).set({status: 'completed'})

				dispatch('checkin/addCheckin', patient, { root: true })
				commit(types.SET_TRANSFER_STATUS, TransferStatus.Success)

				setTimeout(() => {
					commit(types.SET_TRANSFER_STATUS, null)
				}, 10000)
			})
		})
	},
	async unwatchTransfers({ commit, dispatch } : { commit: Commit, dispatch: Dispatch }) {
		const requestsRef = fb.refs.requests
		if (!requestsRef) return
		state.requestsListener = undefined
		state.requestRef = undefined
		state.responseRef = undefined
		state.transferRef = undefined
		return requestsRef.off('child_added')
	},
	async cancelTransfer({ state, commit } : { state: Collection, commit: Commit }) {
		if (state.requestRef) await state.requestRef.remove()
		if (state.responseRef) await state.responseRef.remove()
		if (state.transferRef) await state.transferRef.remove()
		commit(types.SET_TRANSFER_STATUS, TransferStatus.Canceled)
		setTimeout(() => {
			commit(types.SET_TRANSFER_STATUS, null)
		}, 5000)
	},
}

const mutations = {
	[types.SET_OPEN](state: Collection) {
		Vue.set(state, 'open', true)
	},
	[types.UNSET_OPEN](state: Collection) {
		Vue.set(state, 'open', false)
	},
	[types.SET_TRANSFER_STATUS](state: Collection, status: TransferStatus) {
		Vue.set(state, 'status', status)
	},
}

const getters = {
	open: (state: Collection) => state.open,
	status: (state: Collection) => state.status,
}

export default {
	namespaced: true,
	state,
	actions,
	mutations,
	getters,
}
