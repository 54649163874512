
import Axios from 'axios'
import { Commit } from 'vuex'
import { API } from '@/lib/api'
import Cookies from 'js-cookie'

const AUTHKEY = 'drchronoAuth'
const TOKENKEY = 'drchronoToken'

const state = () => ({
	auth: Cookies.get(AUTHKEY),
	token: Cookies.get(TOKENKEY),
})

const actions = {

	async login({ commit }: { commit: Commit }, params: Collection) {
		console.log('loginProvider.params', params)
		try {
			return commit('setAuth', params)
		} catch (error) {
			console.error(error)
		}
	},


	async auth({ commit }: { commit: Commit }, code: string) {
		const api = API.getInstance()

		let res
		try {
			res = await api.get(
				`/provider/auth`,
				{
					code
				},
				{},
				true,
				{
					'X-Referrer': [window.location.origin, window.location.pathname].join(''),
				}
			)
		} catch (error) {
			console.error('error', error)
		}

		return res
	},
	async getPatients({ commit, state }: { commit: Commit, state: Collection }, params: Collection) {
		const api = API.getInstance()
		const { firstName, lastName, email } = params

		const res = await api.get(
			`/provider/patients`,
			{
				firstName,
				lastName,
				email,
			},
			{},
			true,
			{
				token: state.token,
				'X-Referrer': [window.location.origin, window.location.pathname].join(''),
			},
		)

		return res.results
	},

	async getDoctors({ commit, state }: { commit: Commit, state: Collection }, params: Collection) {
		const api = API.getInstance()
		// const { firstName, lastName, email } = params

		const res = await api.get(
			`/provider/doctors`,
			{
			},
			{},
			true,
			{
				token: state.token,
				'X-Referrer': [window.location.origin, window.location.pathname].join(''),
			},
		)

		return res.results
	},

	async addPatient({ commit, state }: { commit: Commit, state: Collection }, data: Collection) {
		const { checkin } = data
		if (!checkin) {
			throw new Error(`Missing required data.`)
		}

		const api = API.getInstance()
		const res = await api.send(
			`/provider/patient`,
			data,
			{},
			true,
			{
				token: state.token,
				'X-Referrer': [window.location.origin, window.location.pathname].join(''),
			},
		)

		return res
	},

	async updatePatient({ commit, state }: { commit: Commit, state: Collection }, data: Collection) {
		const { checkin, patient } = data
		if (!checkin || !patient) {
			throw new Error(`Missing required data. Please make sure an existing patient is selected.`)
		}
		// console.log( { checkin, patient })

		const api = API.getInstance()
		const res = await api.update(
			`/provider/patient`,
			data,
			{},
			true,
			{
				token: state.token,
				'X-Referrer': [window.location.origin, window.location.pathname].join(''),
			},
		)

		return res
	},

	async checkin({ commit, state }: { commit: Commit, state: Collection }, checkin: Collection) {
		const api = API.getInstance()
		const { firstName, lastName, email } = checkin

		const res = await api.get(
			`/provider/patients`, {
				firstName,
				lastName,
				email,
			},
			{},
			true,
			{
				token: state.token,
				'X-Referrer': [window.location.origin, window.location.pathname].join(''),
			},
		)

		return res.results
	},
}

const mutations = {
	setAuth(state: Collection, authData: Collection) {
		let expires = 2
		try {
			expires = Number(authData.expires_in) / 60 / 60 / 24
		} catch (error) {

		}

		Cookies.set(AUTHKEY, JSON.stringify(authData), { secure: true, expires, })
		Cookies.set(TOKENKEY, authData.access_token, { secure: true, expires, })

		state.token = authData.access_token
	}
}

const getters = {
	auth: (state: Collection) => {
		let auth: Collection = {}
		try {
			auth = JSON.parse(Cookies.get(AUTHKEY) )
		} catch (error) {
			console.error(error)
		}

		return auth
	},
	token: (state: Collection) => state.token,
}

export default {
	namespaced: true,
	state,
	actions,
	mutations,
	getters,
}
