




























import Vue from 'vue'
import { MDCDialog } from '@material/dialog'

export default Vue.extend({
	name: 'Confirm',
	data: () => ({
		dialog: {} as MDCDialog,
		defaults: {
			title: 'Are you sure?',
			cancelLabel: `Cancel`,
			acceptLabel: `Yes`,
			cancelAction: () => ({}),
			acceptAction: () => ({}),
			showCancel: true,
		},
		options: {} as object,
	}),
	mounted() {
		this.dialog = new MDCDialog(this.$el)
	},
	methods: {
		show(options: object) {
			this.options = {...this.defaults, ...options}
			this.dialog.open()
		},
		close() {
			this.dialog.close()
		},
	}
})
