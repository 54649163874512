
/**
 * Vue Filters
 */

// Dynamic Import/Export
const files = require.context('.', false, /\.ts$/)
const modules: Collection = {}

files.keys().forEach((key) => {
	if (key === './index.ts') return
	let name = key.replace(/(\.\/|\.ts)/g, '')
	modules[name] = files(key).default
})

export default modules
