/**
 * Vuex Modules: Misc
 */

// Dependencies
import { Commit, Dispatch } from 'vuex'
import * as types from '../types'

const state: Collection = {
	loading: 0 as number,
	error: null as string | null,
}

const actions = {
	setLoading({ commit } : { commit: Commit }, status: boolean) {
		if (status) {
			commit(types.SET_LOADING, 1)
		} else {
			commit(types.SET_LOADING, -1)
		}
	},
	setError({ commit } : { commit: Commit }, error: string) {
		commit(types.SET_ERROR, error)
	},
}

const mutations = {
	[types.SET_LOADING](state: Collection, status: number) {
		state.loading = Math.max(0, state.loading + status)
	},
	[types.SET_ERROR](state: Collection, error: string) {
		state.error = error
	},
}

const getters: Collection = {
	loading: (state: Collection) => !!state.loading,
	error: (state: Collection) => state.error,
}

export default {
	namespaced: true,
	state,
	actions,
	mutations,
	getters,
}
