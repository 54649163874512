


















import Vue from 'vue'
import { MDCSnackbar } from '@material/snackbar'

export default Vue.extend({
	name: 'Notice',
	data: () => ({
		snackbar: {} as MDCSnackbar,
		queued: [] as Object[],
		defaults: {
			timeoutMs: 10000,
		},
		options: {} as Object,
	}),
	mounted() {
		this.snackbar = new MDCSnackbar(this.$el)
		this.snackbar.listen(`MDCSnackbar:closed`, () => {
			this.queued.shift()
			if (this.$el && this.$el.parentNode) {
				this.$el.parentNode.removeChild(this.$el)
			}
		})
	},
	methods: {
		close(): void {
			this.snackbar.close()
		},
	},
	watch: {
		queued (newVal): void {
			if (!newVal.length) return

			this.options = { ...this.defaults, ...this.queued[0] }
			this.snackbar.open()
		},
	},
})
