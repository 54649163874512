import { format } from 'date-fns'

export default function date(val: any = 0, f: string = 'MM/dd/yyyy'): string {
	if (!val) return ''
	if (typeof val.toDate !== 'undefined') {
		val = val.toDate()
	}

	return format(new Date(val), f)
}
