export const MENU_TOGGLE: string = 'MENU_TOGGLE'

export const SET_USER: string = 'SET_USER'
export const UNSET_USER: string = 'UNSET_USER'

export const SET_LOGOUT_WARNING: string = 'SET_LOGOUT_WARNING'
export const SET_LOGOUT_TIMER: string = 'SET_LOGOUT_TIMER'

export const SET_LOADING: string = 'SET_LOADING'
export const SET_ERROR: string = 'SET_ERROR'

export const SET_PROFILES: string = 'SET_PROFILES'
export const SET_PROFILE: string = 'SET_PROFILE'
export const UNSET_PROFILE: string = 'UNSET_PROFILE'

export const SET_OPEN: string = 'SET_OPEN'
export const UNSET_OPEN: string = 'UNSET_OPEN'
export const SET_TRANSFER_STATUS: string = 'SET_TRANSFER_STATUS'

export const ADD_CHECKIN: string = 'ADD_CHECKIN'
export const SET_CHECKIN: string = 'SET_CHECKIN'
export const UNSET_CHECKIN: string = 'UNSET_CHECKIN'
export const SET_CHECKINS: string = 'SET_CHECKINS'
export const UNSET_CHECKINS: string = 'UNSET_CHECKINS'
export const DELETE_CHECKIN: string = 'DELETE_CHECKIN'
export const UPDATE_CHECKIN: string = 'UPDATE_CHECKIN'
export const SET_CHECKIN_LOADING: string = 'SET_CHECKIN_LOADING'

export const ENABLE_NOTIFICATIONS: string = 'ENABLE_NOTIFICATIONS'
export const SEND_NOTIFICATION: string = 'SEND_NOTIFICATION'

export const SET_FORMS: string = 'SET_FORMS'
export const UNSET_FORMS: string = 'UNSET_FORMS'
