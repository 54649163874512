import { v4 } from 'uuid'

export const formFields = require('@/assets/formFields.json')
export const covidFields = require('@/assets/covidFields.json')

export function arrayBufferToString(buffer: Iterable<number>): string {
	const bufView = new Uint16Array(buffer);
	const length = bufView.length;
	let result = '';
	let addition = Math.pow(2, 16) - 1;

	for (let i = 0; i < length; i += addition) {
		if (i + addition > length) {
			addition = length - i;
		}
		result += String.fromCharCode.apply(null, Array.prototype.slice.call(bufView.subarray(i, i + addition)) );
	}

	return result
}

export function smoothScroll() {
	const event = new Event('smoothScroll')
	window.ticking = false
	window.addEventListener('scroll', () => {
		if (!window.ticking) {
			requestAnimationFrame(() => {
				window.dispatchEvent(event)
				window.ticking = false
			})
		}
		window.ticking = true
	})
}

window.imageZoom = function(el: HTMLElement): void {
	const width: number = (window.document as Document).body.clientWidth
	if (width > 640) return
	let scale: number = 1
	let ticking = false

	if (width < 461) {
		scale = 2
	}

	el.addEventListener('mousemove', (event: MouseEvent) => {
		if (!ticking) {
			requestAnimationFrame(() => {
				let percent: number = Math.abs(event.clientX / width) * 100
				el.style.transform = `scale(${scale}) translateX(-${percent}%)`
				ticking = false
			})
		}
		window.ticking = true
	})
}

export function formField(name: string, value: any, profile?: Collection, fields: Collection[] = formFields): Collection {
	const field = fields.find((f: Collection) => f.name === name)
	if (!field) {
		return {
			label: name,
			value
		}
	}

	let match:Collection = {
		type: field.type,
		label: field.label,
		icon: field.icon,
		name: field.name,
		value,
	}

	switch(field.type) {
		case 'dropdown':
			if (field.children[value]) {
				match.value = field.children[value]
			}
			break
		case 'signature':
			if (value) {
				const id = v4()
				match.value = `<a href="#${id}"><img src="data:image/jpg;base64,${value}" class="image"></a><a href="#_" class="lightbox" id="${id}"><img src="data:image/jpg;base64,${value}"></a>`
			}
			break
		case 'image':
			if (value) {
				let filename = field.label
				if (profile) {
					filename = `${profile['lastName']}, ${profile['firstName']} - ${field.label}.jpg`
				}
				const id = v4()
				match.value = `<a href="#${id}"><img src="data:image/jpg;base64,${value}" class="image"></a><a href="#_" class="lightbox" id="${id}"><img src="data:image/jpg;base64,${value}"></a><br><a href="data:image/jpg;base64,${value}" download="${filename}" class="mdc-button mdc-button--unelevated">
				<i class="material-icons mdc-button__icon" aria-hidden="true">get_app</i><span class="mdc-button__label">Download</span>
				</a>`
			}
			break
		case 'checkbox':
			match.value = match.value ? `YES` : `NO`
			// match.value = match.value ? `<i class="material-icons">check_box</i>` : `<i class="material-icons">check_box_outline_blank</i>`
			break
		case 'list':
			let listMatches: string[] = []
			for (const item in value) {
				const found = field.children.find((f: Collection) => f.name === item)
				if (found) {
					listMatches.push(found.label)
				}
			}

			match.value = listMatches.join('\n')
			break
		case 'modList':
			match.value = field.children
			break
	}

	return match
}

export function debounce(fn: Function, d: number) {
	let tid: any
	return function (this: any) {
		clearTimeout(tid)
		const args = arguments
		const that = this
		tid = setTimeout(() => {
			fn.apply(that, args)
		}, d)
	}
}

export async function parseImage(data: string|Collection) {
	let i: any

	let imgData: Collection = await new Promise((resolve, reject) => {
		i = new Image()
		i.onload = (event: any) => resolve(i)
		if (typeof data == 'string') {
			i.src = `data:image/jpg;base64,${data}`
		} else {
			i.src = data.src
		}
	})
	// i = null

	return {
		src: imgData.src,
		height: imgData.height,
		width: imgData.width,
		data,
		obj: i,
	}
}

export async function mergeImage(one: string, two: string) {
	const c = document.createElement('canvas')
	const ctx = c.getContext('2d')
	if (!ctx) return
	const imageOne = await parseImage(one)
	const imageTwo = await parseImage(two)
	c.width = imageOne.width
	c.height = imageOne.height + imageTwo.height

	ctx.drawImage(imageOne.obj, 0, 0, imageOne.width, imageOne.height)
	ctx.drawImage(imageTwo.obj, 0, imageOne.height, imageTwo.width, imageTwo.height)

	const merged = c.toDataURL('image/jpeg')
	return {
		src: merged,
		height: imageOne.height + imageTwo.height,
		width: imageOne.width,
		data: merged.replace('data:image/jpeg;base64,', ''),
	}
}
